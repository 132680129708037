import React from 'react';
import './background.css';

export const Background = () => {
  return (
    <div className='background'>
      <h1>WILL</h1>
    </div>
  );
};
