import React from 'react';
import './about.css';

export const About = () => {
  return (
    <div className='about'>
      <p align='center'>
        I'm a sophomore at <a target='_blank' href='https://wpi.edu' rel="icon">
          WPI
        </a> {' '} studying<br></br><span class="study-focus">Aerospace Engineering</span> and <span class="study-focus">Computer Science</span>
        
        <br></br>

        {/* I'm a self-taught software engineer and fullstack web developer <br></br>{' '} */}
        {/*"I'm also a self-taught photographer and digital artist"*/}
      </p>
    </div>
  );
};
