import './App.css';
import { Hero } from './hero';
import { HeroBg } from './heroBg';
import { Footer } from './Footer';
import { Experience } from './Experience';
import { Skills } from './Skills';
import { About } from './About';
import { Background } from './background';
import { Projects } from './projects';
import React from 'react';

function App() {
  return (
    <div className='container'>
      {/* <HeroBg /> */}
      <Hero />
      <About />
      <Skills />
      <Experience />
      <Projects />
      <Background />
      <Footer></Footer>
    </div>
  );
}

export default App;
