import React from 'react';
import './experience_project_Card.css';

export const ProjectCard = (props) => {
  return (
    <div className='project-card'>
      <h3>{props.title}</h3>
      {props.description}
      {props.link}
    </div>
  );
};
