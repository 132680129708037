import './hero.css';
// import './heroBg2.css';
import React from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const handleMouseLeave = () => {
  const hero = document.querySelector('.hero');
  hero.style.setProperty(
    '--background-dot-x',
    `${-1000 * window.innerWidth}px`
  );
  hero.style.setProperty(
    '--background-dot-y',
    `${-1000 * window.innerWidth}px`
  );
};

let mouseIsShown = false;

const handleHeroClick = () => {
  return;
  document.querySelector('.hero').style.cursor = mouseIsShown
    ? 'none'
    : 'default';
  mouseIsShown = !mouseIsShown;
};

const setUpAnimations = () => {
  const tl = gsap.timeline();
  tl.to('.hero-header', {
    scrollTrigger: {
      trigger: '.hero-header',
      start: 'top -10%',
      end: window.innerHeight,
      scrub: true,
      // markers: true,
    },
    // fontSize: 0,
    // y: -10,
    color: 'hsl(0, 0%, 0%, 0)',
  });
};

export const Hero = () => {
  React.useLayoutEffect(setUpAnimations, []);

  return (
    <div
      className='hero'
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleHeroClick}
    >
      <div id='hero-greeting'>
        <span>
          <p>hi</p>
        </span>
        <span>, i'm</span>
      </div>
      <h1 className='hero-header'>WILL</h1>

      <div className='scroll-tip-container'>
        <button
          className='scroll-tip'
          onClick={() => {
            window.scrollTo({ top: window.innerHeight });
          }}
          onMouseDown={() => {
            mouseIsShown = !mouseIsShown;
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            className='scroll-tip-icon'
          >
            <path d='m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z' />
          </svg>
        </button>
      </div>
    </div>
  );
};
