document.onmousemove = (e) => {
  const hero = document.querySelector('.hero');
  const dotRadius = (Math.max(window.innerWidth, window.innerHeight) * 0.4) / 2;
  const xPos = e.clientX - dotRadius; //Math.floor(e.clientX / dotSize) * dotSize;
  const yPos = e.clientY - dotRadius + window.scrollY; //Math.floor(e.clientY / dotSize) * dotSize;

  hero.style.setProperty('--background-dot-x', `${xPos}px`);
  hero.style.setProperty('--background-dot-y', `${yPos}px`);
};

document.onscroll = (e) => {
  if (window.scrollY > window.innerHeight * 0.2) {
    document.querySelector('.scroll-tip').style.scale = '0';
    document.querySelector('.scroll-tip').style.animationPlayState = 'paused';
  } else {
    document.querySelector('.scroll-tip').style.scale = '1';
    document.querySelector('.scroll-tip').style.animationPlayState = '';
  }
};
