import React from 'react';
import './footer.css';

export const Footer = () => {
  return (
    <footer>
      <p>This page is under development</p>
    </footer>
  );
};
