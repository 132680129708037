import React from 'react';
import './skills.css';

export const Skills = () => {
  return (
    <div className='content-area' id='skills'>
      <h2>Skills</h2>
      <div className='skills-list'>
        <ul>
          <li>MATLAB, Microsoft Excel and Microsoft 365</li>
          <li>Git, Linux/Unix/Bash</li>
          <li>Python - Django</li>
          <li>Objective-C, Java, C#, C++,</li>
          <li>Javascript - Node, React</li>
          <li>Web Design - HTML/CSS</li>
          <li>VScode, Visual Studio, Xcode, IntelliJ, Pycharm,</li>
          <li>Photoshop, Lightroom, After Effects</li>
        </ul>
      </div>
    </div>
  );
};
