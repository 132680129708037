import React from 'react';
import './contentArea.css';
import { ExperienceCard } from './ExperienceCard';

export const Experience = () => {
  return (
    <div className='content-area' id='experience'>
      <h2>Experience</h2>
      <ExperienceCard
        title='Junior Software Engineer (part time)'
        company='Ecamm'
        reponsibilities='Member of a small team working on Ecamm Live software; feature requests, app improvements, and bug fixes'
        timeFrame='August 2022 - Present'
        tools='Objective-C, C++, AppKit, Core Audio, Xcode'
      ></ExperienceCard>
      <ExperienceCard
        title='STEAM Classroom Instructor'
        company='Code & Circuit'
        reponsibilities='Design curriculum and use self-created software to instruct students grades 2-8 in learning to code; work with other instructors and interns to coordinate teaching and lesson plans'
        timeFrame='Summer 2023 - Present'
        tools='Python, Javascript, Node, Linux, VSCode'
      ></ExperienceCard>
      <ExperienceCard
        title='Grocery Store Associate'
        company='Market Basket'
        reponsibilities='Cashier, bag groceries, perform maintenance duties'
        timeFrame='July 2020 - September 2021'
        tools='Customer service'
      ></ExperienceCard>
    </div>
  );
};
