import React from 'react';
import './experience_project_Card.css';

export const ExperienceCard = (props) => {
  return (
    <div className='experience-card'>
      <span>
        <h3>{props.title}</h3>
        <h3 className='experience-company'> at {props.company}</h3>
      </span>
      <p style={{ marginTop: '10px' }}>{props.reponsibilities}</p>
      <p>{props.tools}</p>
      <p>{props.timeFrame}</p>
    </div>
  );
};
